import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-387c5eb0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "content-box"
};
var _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_skeleton = _resolveComponent("el-skeleton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    "class": "content",
    innerHTML: $data.content
  }, null, 8, _hoisted_2), $data.loading ? (_openBlock(), _createBlock(_component_el_skeleton, {
    key: 0,
    rows: 5,
    animated: ""
  })) : _createCommentVNode("", true)]);
}