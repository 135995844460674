import _toConsumableArray from "C:/Users/hujun/Desktop/front-end/GLP-PC/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { scrollToTop } from '@/utils/utils';
export default {
  data: function data() {
    return {
      pageList: [],
      currentPage: 1
    };
  },
  props: {
    total: {
      type: Number,
      "default": 0
    },
    pageSize: {
      type: Number,
      "default": 10
    }
  },
  computed: {
    pageCount: function pageCount() {
      //页码数
      return Math.ceil(this.total / this.pageSize);
    }
  },
  watch: {
    pageCount: function pageCount() {
      this.setPageList(this.currentPage);
    }
  },
  methods: {
    //点击页码
    currentPageChange: function currentPageChange(pageNo) {
      if (pageNo == '...') return;
      this.currentPage = pageNo;
      this.setPageList(pageNo);
      this.$emit('currentChange', pageNo);
      scrollToTop();
    },
    setPageList: function setPageList(pageNo) {
      if (this.pageCount > 5) {
        var tureDiff = this.pageCount - pageNo; //最大页码和当前页码差值
        var diff = tureDiff < 2 ? this.pageCount - pageNo : 2; //用来计算页码的差值，最大不超过2
        if (pageNo <= 3) {
          this.pageList = this.pageList = [1, 2, 3, 4, 5, '...', this.pageCount];
        } else if (tureDiff <= 2) {
          this.pageList = [1, '...', pageNo - 4 + diff, pageNo - 3 + diff, pageNo - 2 + diff, pageNo - 1 + diff, pageNo + diff];
        } else {
          this.pageList = [1, '...', pageNo - 4 + diff, pageNo - 3 + diff, pageNo - 2 + diff, pageNo - 1 + diff, pageNo + diff, '...', this.pageCount];
        }
      } else {
        this.pageList = _toConsumableArray(Array(this.pageCount).keys()).map(function (item) {
          return item + 1;
        });
      }
    },
    // 下一页
    nextPage: function nextPage() {
      this.currentPage++;
      this.setPageList(this.currentPage);
      this.$emit('currentChange', this.currentPage);
      scrollToTop();
    }
  },
  mounted: function mounted() {
    this.setPageList(1);
  }
};