import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "page-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Header = _resolveComponent("Header");
  var _component_router_view = _resolveComponent("router-view");
  var _component_Footer = _resolveComponent("Footer");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Header), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view, null, {
    "default": _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: _ctx.$route.name
      })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: _ctx.$route.name
      })) : _createCommentVNode("", true)];
    }),
    _: 1
  })]), _createVNode(_component_Footer)], 64);
}