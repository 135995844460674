import _createForOfIteratorHelper from "C:/Users/hujun/Desktop/front-end/GLP-PC/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import GlpIns1 from "@/views/sub/glpInstructions01.vue";
import GlpIns2 from "@/views/sub/glpInstructions02.vue";
import GlpIns3 from "@/views/sub/glpInstructions03.vue";
export default {
  components: {
    GlpIns1: GlpIns1,
    GlpIns2: GlpIns2,
    GlpIns3: GlpIns3
  },
  data: function data() {
    return {
      currrentTab: {},
      tabList: [{
        title: "司美格鲁肽注射液",
        checked: 1,
        img: "https://osscgm.nutriease.com/detect_report/cgm/2023/10/09/65d3cabc-3823-4ec6-a891-caca66dbb141.png",
        itemTabList: [{
          title: "药品名称",
          href: "#medicalName",
          checked: 1
        }, {
          title: "成份",
          href: "#chengfen",
          checked: 0
        }, {
          title: "适应症",
          href: "#shiyingzhen",
          checked: 0
        }, {
          title: "用法用量",
          href: "#yongfayongliang",
          checked: 0
        }, {
          title: "特殊人群",
          href: "#people",
          checked: 0
        }, {
          title: "禁忌",
          href: "#jingji",
          checked: 0
        }, {
          title: "注意事项",
          href: "#zhuyishixiang",
          checked: 0
        }, {
          title: "孕妇及哺乳期妇女用药",
          href: "#women",
          checked: 0
        }, {
          title: "药理作用",
          href: "#yaolizuoyong",
          checked: 0
        }, {
          title: "药代动力学",
          href: "#yaodaidonglixue",
          checked: 0
        }
        // {
        //   title: "包装",
        //   href: "#baozhuang",
        //   checked: 0,
        // },
        // {
        //   title: "有效期",
        //   href: "#date",
        //   checked: 0,
        // },
        ]
      }, {
        title: "利拉鲁肽注射液",
        checked: 0,
        img: "https://osscgm.nutriease.com/detect_report/cgm/2023/10/09/3f081785-66af-40ab-a82a-37e7f94b6523.png",
        itemTabList: [{
          title: "药品名称",
          href: "#medicalName",
          checked: 1
        }, {
          title: "成份",
          href: "#chengfen",
          checked: 0
        }, {
          title: "性状",
          href: "#xingzhuang",
          checked: 0
        }, {
          title: "适应症",
          href: "#shiyingzheng",
          checked: 0
        }, {
          title: "规格",
          href: "#guige",
          checked: 0
        }, {
          title: "用法用量",
          href: "#yongfayongliang",
          checked: 0
        }, {
          title: "不良反应",
          href: "#buliangfanying",
          checked: 0
        }, {
          title: "禁忌",
          href: "#jingji",
          checked: 0
        }, {
          title: "注意事项",
          href: "#zhuyishixiang",
          checked: 0
        }, {
          title: "孕妇及哺乳期妇女用药",
          href: "#women",
          checked: 0
        }
        // {
        //   title: "儿童用药",
        //   href: "#kids",
        //   checked: 0,
        // },
        // {
        //   title: "老年用药",
        //   href: "#old",
        //   checked: 0,
        // },
        // {
        //   title: "药物相互作用",
        //   href: "#yaowuhuxiangzuoyong",
        //   checked: 0,
        // },
        // {
        //   title: "药物过量",
        //   href: "#yaowuguoliang",
        //   checked: 0,
        // },
        // {
        //   title: "临床试验",
        //   href: "#linchuangshiyan",
        //   checked: 0,
        // },
        // {
        //   title: "药理毒理",
        //   href: "#yaoliduli",
        //   checked: 0,
        // },
        // {
        //   title: "药代动力学",
        //   href: "#yaodaidonglixue",
        //   checked: 0,
        // },
        // {
        //   title: "贮藏",
        //   href: "#store",
        //   checked: 0,
        // },
        // {
        //   title: "包装",
        //   href: "#baozhuang",
        //   checked: 0,
        // },
        // {
        //   title: "有效期",
        //   href: "#date",
        //   checked: 0,
        // },
        // {
        //   title: "执行标准",
        //   href: "#zhixingbiaozhun",
        //   checked: 0,
        // },
        // {
        //   title: "批准文号",
        //   href: "#pizhunwenhao",
        //   checked: 0,
        // },
        // {
        //   title: "生产企业",
        //   href: "#company",
        //   checked: 0,
        // },
        ]
      }, {
        title: "贝那鲁肽注射液",
        checked: 0,
        img: "https://osscgm.nutriease.com/detect_report/cgm/2023/10/09/5a78c0ab-e27d-420a-9569-d50985293adf.png",
        itemTabList: [{
          title: "药品名称",
          href: "#medicalName",
          checked: 1
        }, {
          title: "成份",
          href: "#chengfen",
          checked: 0
        }, {
          title: "性状",
          href: "#xingzhuang",
          checked: 0
        }, {
          title: "适应症",
          href: "#shiyingzheng",
          checked: 0
        }, {
          title: "规格",
          href: "#guige",
          checked: 0
        }, {
          title: "用法用量",
          href: "#yongfayongliang",
          checked: 0
        }, {
          title: "不良反应",
          href: "#buliangfanying",
          checked: 0
        }, {
          title: "禁忌",
          href: "#jingji",
          checked: 0
        }, {
          title: "注意事项",
          href: "#zhuyishixiang",
          checked: 0
        }, {
          title: "孕妇及哺乳期妇女用药",
          href: "#women",
          checked: 0
        }
        // {
        //   title: "儿童用药",
        //   href: "#kids",
        //   checked: 0,
        // },
        // {
        //   title: "老年用药",
        //   href: "#old",
        //   checked: 0,
        // },
        // {
        //   title: "药物相互作用",
        //   href: "#yaowuhuxiangzuoyong",
        //   checked: 0,
        // },
        // {
        //   title: "药物过量",
        //   href: "#total",
        //   checked: 0,
        // },
        // {
        //   title: "临床试验",
        //   href: "#linchuangshiyan",
        //   checked: 0,
        // },
        // {
        //   title: "药理毒理",
        //   href: "#yaowuduli",
        //   checked: 0,
        // },
        // {
        //   title: "药代动力学",
        //   href: "#yaodaidonglixue",
        //   checked: 0,
        // },
        // {
        //   title: "贮藏",
        //   href: "#store",
        //   checked: 0,
        // },
        // {
        //   title: "有效期",
        //   href: "#date",
        //   checked: 0,
        // },
        ]
      }],

      itemTabList: []
    };
  },
  mounted: function mounted() {
    this.itemTabList = this.tabList[0].itemTabList;
    this.currrentTab = this.tabList[0].itemTabList[0];
  },
  methods: {
    checkTab: function checkTab(item) {
      var _iterator = _createForOfIteratorHelper(this.tabList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var i = _step.value;
          i.checked = 0;
          if (i.title === item.title) {
            i.checked = 1;
            this.itemTabList = i.itemTabList;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    checkItemTab: function checkItemTab(item) {
      var _iterator2 = _createForOfIteratorHelper(this.itemTabList),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var i = _step2.value;
          i.checked = 0;
          if (i.title === item.title) {
            i.checked = 1;
            this.currrentTab = i;
            console.log(this.currrentTab);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    setHover: function setHover(item) {
      if (this.currrentTab.title != item.title) {
        item.checked = 1;
      }
    },
    removeHover: function removeHover(item) {
      if (this.currrentTab.title != item.title) {
        item.checked = 0;
      }
    },
    goAnchor: function goAnchor(item) {
      document.querySelector(item.href).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }
};