import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/index/doctor-avatar.png';
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4330f849"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "expert-help"
};
var _hoisted_2 = {
  "class": "swiper"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  key: 1,
  "class": "avatar",
  src: _imports_0,
  alt: ""
};
var _hoisted_5 = {
  "class": "name"
};
var _hoisted_6 = {
  "class": "position"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    "class": "button left-button",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.swiperLeftClick && $options.swiperLeftClick.apply($options, arguments);
    })
  }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    "class": "list",
    style: _normalizeStyle({
      left: "".concat($data.left, "px")
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.expertList, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      "class": "item",
      key: index
    }, [item.img ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      "class": "avatar",
      src: item.img,
      alt: ""
    }, null, 8, _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4)), _createElementVNode("p", _hoisted_5, _toDisplayString(item.name), 1), _createElementVNode("p", _hoisted_6, _toDisplayString(item.hospitalProfessionalTitle), 1)]);
  }), 128))], 4)]), _createElementVNode("div", {
    "class": "button right-button",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.swiperRightClick && $options.swiperRightClick.apply($options, arguments);
    })
  })]);
}