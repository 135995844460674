import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/index.scss'
var VueTouch = require('vue-touch')
import { Toast } from 'vant';
import 'vant/es/toast/style';

import { randomString } from '@/utils/utils'
localStorage.setItem('subKey', randomString(11))

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App)
app.use(ElementPlus, { zIndex: 3000, locale: zhCn, })
app.use(Toast)
app.use((VueTouch, { name: 'v-touch' })).use(store).use(router).mount('#app')
