import { tableData } from "@/mock/tableData.js";
export default {
  data: function data() {
    return {
      tableData201: tableData.table2["01"],
      tableData202: tableData.table2["02"],
      tableData203: tableData.table2["03"],
      tableData204: tableData.table2["04"]
    };
  },
  mounted: function mounted() {},
  methods: {
    objectSpanMethod: function objectSpanMethod(_ref, tableData) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (row.columnIndex === 0) {
        // 获取当前单元格的值
        var currentValue = row.row[row.column.property];
        // 获取上一行相同列的值
        var preRow = tableData[row.rowIndex - 1];
        var preValue = preRow ? preRow[row.column.property] : null;
        // 如果当前值和上一行的值相同，则将当前单元格隐藏
        if (currentValue === preValue) {
          console.log(tableData);
          return {
            rowspan: 0,
            colspan: 0
          };
        } else {
          // 否则计算当前单元格应该跨越多少行
          var rowspan = 1;
          for (var i = row.rowIndex + 1; i < tableData.length; i++) {
            var nextRow = tableData[i];
            var nextValue = nextRow[row.column.property];
            if (nextValue === currentValue) {
              rowspan++;
            } else {
              break;
            }
          }
          return {
            rowspan: rowspan,
            colspan: 1
          };
        }
      }
    }
  }
};