import "core-js/modules/web.timers.js";
export default {
  data: function data() {
    return {
      index: 0,
      left: 0,
      lock: false
    };
  },
  props: {
    expertList: {
      type: Array,
      "default": []
    }
  },
  methods: {
    swiperLeftClick: function swiperLeftClick() {
      var _this = this;
      if (this.expertList.length < 4 || this.lock) return;
      if (this.index <= 0) {
        this.index--;
        this.left += 50;
        setTimeout(function () {
          _this.index++;
          _this.left -= 50;
        }, 200);
      } else {
        this.setLock();
        this.index--;
        this.left += 224 + 44;
      }
    },
    swiperRightClick: function swiperRightClick(type) {
      var _this2 = this;
      if (this.expertList.length < 4 || this.lock) return;
      if (this.expertList.length - this.index <= 4) {
        this.index++;
        this.left -= 50;
        setTimeout(function () {
          _this2.index--;
          _this2.left += 50;
        }, 200);
      } else {
        this.setLock();
        this.index++;
        this.left -= 224 + 44;
      }
    },
    setLock: function setLock() {
      var _this3 = this;
      this.lock = true;
      setTimeout(function () {
        _this3.lock = false;
      }, 500);
    }
  }
};