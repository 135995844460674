import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7af470ce"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "nav"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  "class": "questionList"
};
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  key: 0,
  "class": "empty-tip"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_skeleton = _resolveComponent("el-skeleton");
  var _component_Pagination = _resolveComponent("Pagination");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.typeList, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      onClick: function onClick($event) {
        return $options.selectType(item);
      },
      "class": _normalizeClass({
        'active': $data.activeType === item.type
      })
    }, _toDisplayString(item.name), 11, _hoisted_2);
  }), 128))]), _withDirectives(_createElementVNode("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.questionList, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      "class": "qustion-item",
      key: index
    }, [_createElementVNode("h3", {
      innerHTML: item.title
    }, null, 8, _hoisted_4), _createElementVNode("div", {
      "class": "answer",
      innerHTML: item.content
    }, null, 8, _hoisted_5)]);
  }), 128))], 512), [[_vShow, !$data.loading]]), !$data.questionList.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, "没有找到要搜索的内容")) : _createCommentVNode("", true), $data.loading ? (_openBlock(), _createBlock(_component_el_skeleton, {
    key: 1,
    rows: 5,
    animated: ""
  })) : _createCommentVNode("", true), _createVNode(_component_Pagination, {
    ref: "Pagination",
    onCurrentChange: $options.currentChange,
    pageSize: $data.pageSize,
    total: $data.total,
    style: {
      "margin": "55px 0"
    }
  }, null, 8, ["onCurrentChange", "pageSize", "total"])]);
}