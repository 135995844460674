import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '@/views/index/index.vue'
import Sub from '@/views/sub/index.vue'
import ExpertAnswer from '@/views/sub/expertAnswer.vue'
import GlpMedicine from '@/views/sub/glpMedicine.vue'
import MedicationGuidance from '@/views/sub/medicationGuidance.vue'
import ClinicalGuideline from '@/views/sub/clinicalGuideline.vue'
import Qs from '@/views/qs/index.vue'
import TakeTest from '@/views/qs/takeTest.vue'
import TakeDiss from '@/views/qs/takeDiss.vue'
import Article from '@/views/sub/article.vue'

let routes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    component: Index,
    name: 'Index',
    meta: {
      title: '首页',
      keepAlive: true
    }
  },
  {
    path: '/qs',
    component: Qs,
    name: 'Qs',
    children: [
      {
        path: '/takeTest',
        component: TakeTest,
        name: 'TakeTest',
        meta: {
          title: '我是否适合使用GLP-1'
        }
      }, {
        path: '/takeDiss',
        component: TakeDiss,
        name: 'TakeDiss',
        meta: {
          title: '我可以减重多少'
        }
      }
    ]
  },
  {
    path: '/sub',
    component: Sub,
    name: 'Sub',
    children: [
      {
        path: '/expertAnswer',
        component: ExpertAnswer,
        name: 'ExpertAnswer',
        meta: {
          title: '专家答疑'
        }
      },
      {
        path: '/glpMedicine',
        component: GlpMedicine,
        name: 'GlpMedicine',
        meta: {
          title: 'GLP-1药物'
        }
      },
      {
        path: '/medicationGuidance',
        component: MedicationGuidance,
        name: 'MedicationGuidance',
        meta: {
          title: '用药指导'
        }
      },
      {
        path: '/clinicalGuideline',
        component: ClinicalGuideline,
        name: 'ClinicalGuideline',
        meta: {
          title: '临床指南'
        }
      },
      {
        path: '/article',
        component: Article,
        name: 'Article',
        meta: {
          title: '文章详情'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
