import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-01ba0938"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "glp-medical"
};
var _hoisted_2 = {
  "class": "tab-box flex-row"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  "class": "title"
};
var _hoisted_6 = {
  "class": "content-box"
};
var _hoisted_7 = {
  "class": "flex-row item-tab"
};
var _hoisted_8 = ["onMouseover", "onMouseout", "onClick"];
var _hoisted_9 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_GlpIns1 = _resolveComponent("GlpIns1");
  var _component_GlpIns2 = _resolveComponent("GlpIns2");
  var _component_GlpIns3 = _resolveComponent("GlpIns3");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabList, function (item) {
    return _openBlock(), _createElementBlock("div", {
      "class": _normalizeClass(["tab-item shrink-0 items-center justify-center", item.checked ? 'tab-active' : '']),
      key: item.title,
      onClick: function onClick($event) {
        return $options.checkTab(item);
      }
    }, [_createElementVNode("div", null, [_createElementVNode("img", {
      src: item.img,
      alt: ""
    }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1)])], 10, _hoisted_3);
  }), 128))]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.itemTabList, function (item) {
    return _openBlock(), _createElementBlock("div", {
      "class": _normalizeClass(["item-in-tab shrink-0 items-center justify-center", item.checked ? 'item-tab-active' : '']),
      onMouseover: function onMouseover($event) {
        return $options.setHover(item);
      },
      onMouseout: function onMouseout($event) {
        return $options.removeHover(item);
      },
      key: item.title,
      onClick: function onClick($event) {
        return $options.checkItemTab(item);
      }
    }, [_createElementVNode("span", {
      onClick: function onClick($event) {
        return $options.goAnchor(item);
      }
    }, _toDisplayString(item.title), 9, _hoisted_9)], 42, _hoisted_8);
  }), 128))]), $data.tabList[0].checked ? (_openBlock(), _createBlock(_component_GlpIns1, {
    key: 0
  })) : _createCommentVNode("", true), $data.tabList[1].checked ? (_openBlock(), _createBlock(_component_GlpIns2, {
    key: 1
  })) : _createCommentVNode("", true), $data.tabList[2].checked ? (_openBlock(), _createBlock(_component_GlpIns3, {
    key: 2
  })) : _createCommentVNode("", true)])]);
}