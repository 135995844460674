export const randomString = (e) => {
    e = e || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n
}

export const fileViewer = function (url) {
    const fileTypeList = ['.pdf', '.doc', '.docx', '.xls', '.xlsx']
    const fileType = fileTypeList.find(item => url.indexOf(item) !== -1)
    console.log(fileType)
    if (fileType == '.pdf') {//pdf文件
        let fileUrl = encodeURIComponent(url)
        window.open("https://m.nutriease.com/pdfjs/web/viewer.html?file=" + fileUrl);
    } else if (!fileType) {//其他文件
        window.open(url);
    } else {//office文件
        const fileUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(url);
        window.open(fileUrl);
    }
}

export function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(
                ret[1],
                ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
            );
        }
    }
    return fmt;
}

/**
 * 年龄计算
 */
export function getAge(val) {
    let currentYear = new Date().getFullYear() //当前的年份
    let calculationYear = new Date(val).getFullYear() //计算的年份
    const wholeTime = currentYear + val.substring(4) //周岁时间
    const calculationAge = currentYear - calculationYear //按照年份计算的年龄
    //判断是否过了生日
    if (new Date().getTime() > new Date(wholeTime).getTime()) {
        return calculationAge
    } else {
        return calculationAge - 1
    }
}

export function scrollToTop() {
    let sTop = document.documentElement.scrollTop || document.body.scrollTop
    if (sTop > 0) {
        window.requestAnimationFrame(scrollToTop)
        window.scrollTo(0, sTop - sTop / 8)
    }
}