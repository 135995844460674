export const tableData = {
    table1: {},
    table2: {
        '01': [
            {
                id: "单药治疗",
                key1: "1.8mg利拉鲁肽",
                key2: "1.2mg利拉鲁肽",
                key3: "格列美脲",
            },
            {
                id: "受试者例数(N)",
                key1: "246",
                key2: "251",
                key3: "248",
            },
            {
                id: "HbA1c(%)(均数)",
                key1: "",
                key2: "",
                key3: "",
            },
            {
                id: "基线",
                key1: "8.19",
                key2: "8.18",
                key3: "8.23",
            },
            {
                id: "自基线的变化",
                key1: "-1.14",
                key2: "-0.84",
                key3: "-0.51",
            },
            {
                id: "HbA1c＜7%的患者(%)",
                key1: "",
                key2: "",
                key3: "",
            },
            {
                id: "所有患者",
                key1: "50.9",
                key2: "42.8",
                key3: "27.8",
            },
            {
                id: "既往接受饮食控制的患者",
                key1: "62.0",
                key2: "58.3",
                key3: "30.8",
            },
            {
                id: "体重(kg)(均数)",
                key1: "",
                key2: "",
                key3: "",
            },
            {
                id: "基线",
                key1: "92.6",
                key2: "92.1",
                key3: "93.3",
            },
            {
                id: "自基线的变化",
                key1: "-2.45",
                key2: "-2.05",
                key3: "1.12",
            }
        ],
        '02': [
            {
                id: "与二甲双胍联合治疗",
                key1: "1.8mg利拉鲁肽＋二甲双胍",
                key2: "1.2mg利拉鲁肽＋二甲双胍",
                key3: "安慰剂＋二甲双胍",
                key4: "格列美脲＋二甲双胍"
            },
            {
                id: "受试者例数(N)",
                key1: "242",
                key2: "240",
                key3: "121",
                key4: "242"
            },
            {
                id: "HbA1c(%)(均数)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "基线",
                key1: "8.4",
                key2: "8.3",
                key3: "8.4",
                key4: "8.3",
            },
            {
                id: "自基线的变化",
                key1: "-1.00",
                key2: "-0.97",
                key3: "0.09",
                key4: "-0.98",
            },
            {
                id: "HbA1c＜7%的患者(%)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "所有患者",
                key1: "42.4",
                key2: "35.3",
                key3: "10.8",
                key4: "36.3",
            },
            {
                id: "既往OAD单药治疗",
                key1: "66.3",
                key2: "52.8",
                key3: "22.5",
                key4: "56.0",
            },
            {
                id: "体重(kg)",
                key1: "",
                key2: "",
                key3: "",
                key4: "",
            },
            {
                id: "基线",
                key1: "88.0",
                key2: "88.5",
                key3: "91.0",
                key4: "89.0"
            },
            {
                id: "自基线的变化",
                key1: "-2.79",
                key2: "-2.58",
                key3: "-1.51",
                key4: "0.95"
            },
            {
                id: "与格列美脲联合治疗",
                key1: "1.8mg利拉鲁肽＋格列美脲",
                key2: "1.2mg利拉鲁肽＋格列美脲",
                key3: "安慰剂＋格列美脲",
                key4: "罗格列酮＋格列美脲"
            },
            {
                id: "受试者例数(N)",
                key1: "234",
                key2: "228",
                key3: "114",
                key4: "231"
            },
            {
                id: "平均HbA1c(%)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "基线",
                key1: "8.5",
                key2: "8.5",
                key3: "8.4",
                key4: "8.4"
            },
            {
                id: "自基线的变化",
                key1: "-1.13",
                key2: "-1.08",
                key3: "0.23",
                key4: "-0.44"
            },
            {
                id: "HbA1c＜7%的患者(%)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "所有患者",
                key1: "41.6",
                key2: "34.5",
                key3: "7.5",
                key4: "21.9"
            },
            {
                id: "既往OAD单药治疗",
                key1: "55.9",
                key2: "57.4",
                key3: "11.8",
                key4: "36.1"
            },
            {
                id: "平均体重(kg)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "基线",
                key1: "83.0",
                key2: "80.0",
                key3: "81.9",
                key4: "80.6"
            },
            {
                id: "自基线的变化",
                key1: "-0.23",
                key2: "0.32",
                key3: "-0.10",
                key4: "2.11"
            }
        ],
        '03': [
            {
                id: "与二甲双胍＋罗格列酮联合治疗",
                key1: "1.8mg利拉鲁肽＋二甲双胍＋罗格列酮",
                key2: "1.2mg利拉鲁肽＋二甲双胍＋罗格列酮",
                key3: "安慰剂＋二甲双胍＋罗格列酮",
                key4: "N/A"
            },
            {
                id: "受试者例数(N)",
                key1: "178",
                key2: "177",
                key3: "175",
                key4: ""
            },
            {
                id: "平均HbA1c(%)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "基线",
                key1: "8.56",
                key2: "8.48",
                key3: "8.42",
                key4: "",
            },
            {
                id: "自基线的变化",
                key1: "-1.48",
                key2: "-1.48",
                key3: "-0.54",
                key4: "",
            },
            {
                id: "HbA1c＜7%的患者(%)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "所有患者",
                key1: "53.7",
                key2: "57.5",
                key3: "28.1",
                key4: "",
            },
            {
                id: "平均体重(kg)",
                key1: "",
                key2: "",
                key3: "",
                key4: "",
            },
            {
                id: "基线",
                key1: "94.9",
                key2: "95.3",
                key3: "98.5",
                key4: ""
            },
            {
                id: "自基线的变化",
                key1: "-2.02",
                key2: "-1.02",
                key3: "0.60",
                key4: ""
            },
            {
                id: "与二甲双胍＋格列美脲联合治疗",
                key1: "1.8mg利拉鲁肽＋二甲双胍＋格列美脲",
                key2: "N/A",
                key3: "安慰剂＋二甲双胍＋格列美脲",
                key4: "甘精胰岛素＋二甲双胍＋格列美脲"
            },
            {
                id: "受试者例数(N)",
                key1: "230",
                key2: "",
                key3: "114",
                key4: "232"
            },
            {
                id: "平均HbA1c(%)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "基线",
                key1: "8.3",
                key2: "",
                key3: "8.3",
                key4: "8.1"
            },
            {
                id: "自基线的变化",
                key1: "-1.13",
                key2: "",
                key3: "-0.24",
                key4: "-1.09"
            },
            {
                id: "HbA1c＜7%的患者(%)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "所有患者",
                key1: "53.1",
                key2: "",
                key3: "15.3",
                key4: "45.8"
            },
            {
                id: "平均体重(kg)",
                key1: "",
                key2: "",
                key3: "",
                key4: ""
            },
            {
                id: "基线",
                key1: "85.8",
                key2: "",
                key3: "85.4",
                key4: "85.2"
            },
            {
                id: "自基线的变化",
                key1: "-1.81",
                key2: "",
                key3: "-0.42",
                key4: "1.62"
            }
        ],
        '04': [
            {
                id: "自我监测空腹血糖(FPG)",
                key1: "甘精胰岛素剂量的增加(单位)",
            },
            {
                id: "≤5.5mmol/l(≤100mg/dl)目标",
                key1: "无需调整"
            },
            {
                id: "＞5.5且＜6.7mmol/l(＞100且＜120mg / dl)",
                key1: "0-2IUa"
            },
            {
                id: "≥6.7mmol/l(≥120mg/dl)",
                key1: "2IU"
            },
        ],
    },
    table3: {
        '01': [
            {
                id: "身体系统/不良反应",
                key1: "发生频率",
                key2: "",
            },
            {
                id: "身体系统/不良反应",
                key1: "常见(≥1%且＜10%)",
                key2: "非常常见(≥10%)",
            },
            {
                id: "代谢及营养类疾病",
                key1: "",
                key2: "",
            },
            {
                id: "低血糖",
                key1: "△",
                key2: "",
            },
            {
                id: "厌食",
                key1: "△",
                key2: "",
            },
            {
                id: "食欲下降",
                key1: "△",
                key2: "",
            },
            {
                id: "神经系统疾病",
                key1: "",
                key2: "",
            },
            {
                id: "头晕",
                key1: "",
                key2: "△",
            },
            {
                id: "头痛",
                key1: "△",
                key2: "",
            },
            {
                id: "胃肠系统疾病",
                key1: "",
                key2: "",
            },
            {
                id: "恶心",
                key1: "",
                key2: "x",
            },
            {
                id: "呕吐",
                key1: "x",
                key2: "",
            },
            {
                id: "全身性疾病及给药部位反应",
                key1: "",
                key2: "",
            },
            {
                id: "乏力",
                key1: "x",
                key2: "",
            },
        ],
        '02': [
            {
                id: "组别",
                key1: "全分析集(FAS)",
                key2: "",
                key3: "",
            },
            {
                id: "组别",
                key1: "基线值(%)",
                key2: "治疗第12周(%)",
                key3: "HbA1c降低值(%)",
            },
            {
                id: "安慰剂组",
                key1: "8.06±1.40",
                key2: "8.16±1.74",
                key3: "-0.10±1.43",
            },
            {
                id: "组",
                key1: "",
                key2: "",
                key3: "",
            },
            {
                id: "0.1mg组",
                key1: "8.25±1.41",
                key2: "7.75±1.66",
                key3: "0.49±1.43",
            },
            {
                id: "0.2mg组",
                key1: "8.21±1.39",
                key2: "7.69±1.73",
                key3: "0.52±1.41",
            },
        ]
    }
}