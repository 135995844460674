import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d84719ca"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "pagination"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.pageList, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      "class": _normalizeClass(["page", {
        'page-active': $data.currentPage == item,
        'page-ellipsis': item == '...'
      }]),
      onClick: function onClick($event) {
        return $options.currentPageChange(item);
      },
      key: index
    }, _toDisplayString(item), 11, _hoisted_2);
  }), 128)), $options.pageCount > $data.currentPage ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "class": "page",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.nextPage && $options.nextPage.apply($options, arguments);
    })
  }, "下一页")) : _createCommentVNode("", true), $props.total > 0 ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    "class": _normalizeClass(["page", {
      'page-active': $options.pageCount == $data.currentPage
    }]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.currentPageChange($options.pageCount);
    })
  }, "末页", 2)) : _createCommentVNode("", true)]);
}