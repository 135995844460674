import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  data: function data() {
    return {};
  },
  components: {
    Header: Header,
    Footer: Footer
  },
  watch: {},
  methods: {}
};