import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6a2b34f8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "medication-guidance"
};
var _hoisted_2 = {
  "class": "first-line"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  "class": "cover"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  "class": "title"
};
var _hoisted_7 = {
  "class": "ellipsis"
};
var _hoisted_8 = ["innerHTML"];
var _hoisted_9 = {
  "class": "other-line"
};
var _hoisted_10 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_skeleton = _resolveComponent("el-skeleton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.loaded ? (_openBlock(), _createBlock(_component_el_skeleton, {
    key: 0,
    rows: 3,
    animated: ""
  })) : _createCommentVNode("", true), _createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list1, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      "class": "item",
      onClick: function onClick($event) {
        return $options.handleClick(item.title);
      },
      key: index
    }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
      src: item.cover,
      alt: ""
    }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("h3", _hoisted_7, _toDisplayString(item.title), 1), _createElementVNode("div", {
      "class": "context",
      innerHTML: item.content
    }, null, 8, _hoisted_8)])], 8, _hoisted_3);
  }), 128))]), _createElementVNode("ul", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list2, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      "class": "ellipsis",
      onClick: function onClick($event) {
        return $options.handleClick(item.title);
      },
      key: index
    }, _toDisplayString(item.title), 9, _hoisted_10);
  }), 128))])]);
}