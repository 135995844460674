import { createStore } from 'vuex'

export default createStore({
  state: {
    searchValue: '',
    showNavLogo: false,
    scanImg: {},
    articleUrl: '',
  },
  getters: {
  },
  mutations: {
    UPDATE_SEARCH_VALUE(state, newVal) {
      state.searchValue = newVal
    },
    UPDATE_SHOWNAVLOGO_VALUE(state, newVal) {
      state.showNavLogo = newVal
    },
    UPDATE_SCANIMG(state, newVal) {
      state.scanImg = newVal
    },
    UPDATE_ARTICLE_URL(state, newVal) {
      state.articleUrl = newVal
    }
  },
  actions: {
  },
  modules: {
  }
})
