import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-eaa476e0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "questionList"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  "class": "answer"
};
var _hoisted_5 = {
  style: {
    "text-indent": "12px"
  }
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  key: 0,
  "class": "empty-tip"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Pagination = _resolveComponent("Pagination");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.questionList, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      "class": "qustion-item",
      key: index,
      onClick: function onClick($event) {
        return $options.redirectUrl(item.id);
      }
    }, [_createElementVNode("h3", {
      innerHTML: item.title
    }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(item.description), 1), item.otherParameter ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.otherParameter.releaseDay), 1)) : _createCommentVNode("", true)])], 8, _hoisted_2);
  }), 128))]), !$data.questionList.length ? (_openBlock(), _createElementBlock("div", _hoisted_7, "没有找到要搜索的内容")) : _createCommentVNode("", true), _createVNode(_component_Pagination, {
    onCurrentChange: $options.currentChange,
    pageSize: $data.pageSize,
    total: $data.total,
    style: {
      "margin": "55px 0"
    }
  }, null, 8, ["onCurrentChange", "pageSize", "total"])]);
}