import _objectSpread from "C:/Users/hujun/Desktop/front-end/GLP-PC/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapMutations, mapState } from 'vuex';
export default {
  data: function data() {
    return {
      routeList: [{
        name: '专家答疑',
        path: '/expertAnswer'
      }, {
        name: 'GLP-1药物',
        path: '/glpMedicine'
      }, {
        name: '用药指导',
        path: '/medicationGuidance'
      }, {
        name: '临床指南',
        path: '/clinicalGuideline'
      }],
      searchText: ''
    };
  },
  computed: _objectSpread({}, mapState(['searchValue'])),
  watch: {
    searchValue: {
      handler: function handler(newValue) {
        this.searchText = newValue;
      },
      immediate: true
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations(['UPDATE_SEARCH_VALUE'])), {}, {
    handleSearch: function handleSearch() {
      this.UPDATE_SEARCH_VALUE(this.searchText);
    }
  }),
  beforeRouteLeave: function beforeRouteLeave() {
    this.UPDATE_SEARCH_VALUE('');
  }
};